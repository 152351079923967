import { checkPermission } from '@/utils'
export function list () {
  return {
    column: [
      {
        label: '专题商品',
        value: '0',
        permission: checkPermission('externaladmin:market:addProduct:topics'),
        // getList: getPrototypeList,
        // resetMergeData: {
        //   marketingCategoryId : this.$route.query.id
        // },
        childPermission: {
          batchTopic: checkPermission('externaladmin:market:addProduct:all:batchTopic'),
          // add: checkPermission('externaladmin:market:addProduct:topics:add'),
          remove: checkPermission('externaladmin:market:addProduct:topics:remove'),
          submit: checkPermission('externaladmin:market:addProduct:topics:submit'),
          back: checkPermission('externaladmin:market:addProduct:topics:back')
        },
        option: {
          border: true,
          page: false,
          resetBtn: false,
          isSearchAuto: false,
          column: [
            {
              label: '款式码',
              prop: 'style',
              hide: true,
              search: true
            },
            {
              label: '原型',
              prop: 'chineseName'
            },
            {
              label: '款式',
              prop: 'style'
            }
          ]
        }
      },
      {
        label: '全部商品',
        value: '1',
        permission: checkPermission('externaladmin:market:addProduct:all'),
        // getList: getPrototypeList,
        // resetMergeData: {
        //   isPayOrder: 1
        // },
        childPermission: {
          addTopic: checkPermission('externaladmin:market:addProduct:all:addTopic'),
          add: checkPermission('externaladmin:market:addProduct:all:add'),
          remove: checkPermission('externaladmin:market:addProduct:all:remove'),
          submit: checkPermission('externaladmin:market:addProduct:topics:submit'),
          back: checkPermission('externaladmin:market:addProduct:topics:back')
        },
        option: {
          border: true,
          resetBtn: false,
          isSearchAuto: false,
          column: [
            {
              label: '产品分类',
              prop: 'marketingCategoryId',
              type:'select',
              hide: true,
              search: true
            },
            {
              label: '款式',
              prop: 'style',
              hide: true,
              search: true
            },

            {
              label: '原型',
              prop: 'chineseName'
            },
            {
              label: '款式',
              prop: 'style'
            },
            {
              label: '状态',
              prop: 'goodsStatus'
            }
          ]
        }
      }
    ]
  }

}
